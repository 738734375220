import { Controller } from "stimulus"

window.initExerciseMap = function () {
  const mapPoints = $('#exercise-map').data("exercise-map-map_points");
  const prefixPath = $('#exercise-map').data("exercise-map-prefix_path");

  // Locate To Shandong
  const center = new TMap.LatLng(36.6702, 119.0207);
  const zoom = 8;

  window.exercise_map = new TMap.Map("exercise-map", {
    center,
    zoom
  });

  const geometries = mapPoints.map(function(m) {
    const properties = {
      id: m.id,
      title: m.title,
      site_address: m.site_address,
      bg_style: m.bg_style,
      contact_name: m.contact_name,
      public_phone: m.public_phone
    }

    return {
      styleId: m.mark_style,
      position: new TMap.LatLng(m.lat, m.lng),
      properties
    };
  });

  var marker = new TMap.MultiMarker({
    id: 'marker-layer',
    map: window.exercise_map,
    styles: {
      "marker_default": new TMap.MarkerStyle({
          "width": 21,
          "height": 30,
          "anchor": { x: 14, y: 24 },
          "src": "/images/marker_week_end.png"
      })
    },
    geometries: geometries
  });

  var infoWindow = new TMap.InfoWindow({
    map: window.exercise_map,
    position: new TMap.LatLng(36.6702, 119.0207),
    offset: { x: -4, y: -14 } // 设置信息窗相对position偏移像素
  });
  infoWindow.close();

  function info_window_close() {
    infoWindow.close();
  }

  exercise_map.on("rightclick", info_window_close);

  function marker_click(evt) {
    infoWindow.open();
    infoWindow.setPosition(evt.geometry.position);
    const props = evt.geometry.properties;

    const content = `
      <div class="card text-white mb-3 ${props.bg_style}">
        <div class="card-header">${props.title}</div>
        <div class="card-body text-primary">
          <table class="table text-white">
            <thead class="table-borderless">
              <tr>
                <th scope="col">站点 ID</th>
                <th scope="col">地址</th>
                <th scope="col">联系人姓名</th>
                <th scope="col">联系人电话</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><a href="/${prefixPath}/exercise_sites/${props.id}" target="_blank" class="text-white">EXS_${props.id}</a></td>
                <td>${props.site_address}</td>
                <td>${props.contact_name}</td>
                <td>${props.public_phone}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>`;
    infoWindow.dom.children[0].style["text-align"] = "left";
    infoWindow.dom.children[0].style["line-height"] = "1";
    infoWindow.dom.children[0].style["padding"] = "unset";
    infoWindow.setContent(content);
  }

  marker.on("click", marker_click);
}

export default class extends Controller {
  connect() {
    if (typeof TMap != 'object') {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://map.qq.com/api/gljs?v=1.exp&key=2WEBZ-RABKW-ZDQRR-ONT62-COIC7-VOBOV&callback=initExerciseMap";
      document.body.appendChild(script);
    } else {
      window.initExerciseMap();
    }
  }

  disconnect() {
    window.exercise_map.destroy();
    window.exercise_map = null;
  }
}
