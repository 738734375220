import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "select" ]

  connect() {
    $(this.selectTarget).selectize({
      plugins: []
    });
  }

  disconnect() {
    const need_destroy = $(this.selectTarget)[0].selectize;
    if(need_destroy) {
      need_destroy.destroy();
    }
  }
}
