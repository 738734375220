import { Controller } from "stimulus"

let instructorProjectSelect;

export default class extends Controller {
  connect() {
    instructorProjectSelect = $('#select-instructors-project-id').selectize({
      plugins: []
    });
  }

  disconnect() {
    const need_destroy = instructorProjectSelect[0].selectize;
    if(need_destroy) {
      need_destroy.destroy();
    }
  }
}
