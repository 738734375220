import { Controller } from "stimulus"

let userSubdistrictSelect;

export default class extends Controller {
  connect() {
    userSubdistrictSelect = $('#user_subdistrict_id').selectize({
      plugins: []
    });
  }

  disconnect() {
    const need_destroy = userSubdistrictSelect[0].selectize;
    if(need_destroy) {
      need_destroy.destroy();
    }
  }
}
