import { Controller } from "stimulus"

let applyInstructorSelect;

export default class extends Controller {
  connect() {
    applyInstructorSelect = $('#apply-instructor-select').selectize({
      plugins: [],
      valueField: "id",
      labelField: "name",
      searchField: "name",
      create: false,
      load: function (query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: "/ui/instructor_select.json?q=" + encodeURIComponent(query),
          type: "GET",
          error: function () {
            callback();
          },
          success: function (res) {
            callback(res.instructors);
          },
        });
      },
    });
  }

  disconnect() {
    const need_destroy = applyInstructorSelect[0].selectize;
    if(need_destroy) {
      need_destroy.destroy();
    }
  }
}
