import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    modelName: String
  }

  change(event) {
    const current_target = $(event.target);
    const address = current_target.val();
    $.ajax('/ui/address_changed', {
      data: { address, model_name: this.modelNameValue },
      dataType: 'script'
    });
  }
}
